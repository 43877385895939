<template>
  <div class="home">
    <GradesMentor />
  </div>
</template>

<script>
// @ is an alias to /src
import GradesMentor from '@/components/grade/GradesMentor'

export default {
  name: 'GradesMentorView',
  components: {
      GradesMentor
  }
}
</script>