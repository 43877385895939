<template>
  <div class="section">
    <h1 class="subtitle">{{t('componentNames.mentorGrades')}}</h1>
    <b-table
      :data="exams"
      :show-detail-icon="showDetailIcon"
      detailed
    >
      <b-table-column field="name" :label="t('exam.header')" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="student" :label="t('common.student')" v-slot="props">
        {{ props.row.student }}
      </b-table-column>
      <b-table-column field="grade" :label="t('common.grade')" v-slot="props">
        {{ countGrade(props.row) }} / {{ props.row.questions.length }}
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <div class="columns is-multiline">
          <div
            class="column is-4"
            v-for="item in props.row.questions"
            :key="item.id"
          >
            <p>{{ item.text }}</p>
            <ul>
              <li
                :class="{
                  'text-sucess': option.id === item.goodAnswer,
                  'text-danger':
                    option.id === item.answer && option.id !== item.goodAnswer,
                }"
                v-for="option in item.options"
                :key="option.id"
              >
                {{ option.text }}
                <span v-if="option.id === item.answer">({{t('question.answer')}})</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import examTemp from "./exampleStudent.json";

export default {
  name: "GradesMentor",
  mixins: [CommonHelpers],
  components: {},

  props: {},

  data: function () {
    return {
      exams: [examTemp],
      showDetailIcon: true,
    };
  },

  mounted() {},

  watch: {},

  computed: {},

  methods: {
    countGrade(exam) {
      let goodQuestion = exam.questions.filter(
        (q) => q.answer === q.goodAnswer
      );

      return goodQuestion.length;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: #006600;
}
</style>